
<template>
 <vx-card>
  <form-wizard v-if="$route.params._id" color="rgba(var(--vs-primary), 1)" title="Enregistrez un client" :subtitle="null" finishButtonText="Enregistrer" @on-complete="formSubmitted">
      <tab-content title="" class="mb-5">

        <!-- tab 1 content -->
        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="IdOfficine" v-model="depenseData.IdOfficine" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="codeValidation"  v-model="depenseData.codeValidation" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="Beneficiaire" v-model="depenseData.Beneficiaire" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="Montant"  v-model="depenseData.Montant" class="w-full" />
            </div>

            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="NumCheque"  v-model="depenseData.NumCheque" class="w-full" />
            </div>
        </div>
      </tab-content>

      <!-- tab 2 content -->
      <tab-content title="" class="mb-5">
          <div class="vx-row">
            <div vs-type="flex" vs-justify="center" class="vx-col mt-5">
                <div class="centerx" vs-type="flex" vs-justify="center">
                    <b-form-file
                        v-model="depenseData.CheminPreuv"
                        @change="showCheminPreuvData"
                        :state="Boolean(CheminPreuv)"
                        placeholder="Selectionnez la photo ..."
                        drop-placeholder="Glissez-deposez la photo ici..."
                        ></b-form-file>
                </div>
                <div class="centerx" vs-type="flex" vs-justify="center">
                    {{CheminPreuv}}
                    <img :src="CheminPreuv[0]" alt="" width="200" height="200" >
                </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-5">
                <flat-pickr :config="configTimePicker" v-model="depenseData.HeurOp" placeholder="HeurOp" />
            </div>
            <div class="vx-col md:1/3 w-full mt-5">
                <flat-pickr :config="configDatePicker" v-model="depenseData.DateOp" placeholder="DateOp" />
            </div>

          </div>
      </tab-content>
      <tab-content class="mb-5">
          <!-- tab 3 content -->
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="Motif"  v-model="depenseData.Motif" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="IdUser"  v-model="depenseData.IdUser" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="MoyPaiement" v-model="depenseData.MoyPaiement" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-8">
                <vs-checkbox label="Encaiss_Vrai" v-model="depenseData.Encaiss_Vrai" class="w-full" > Encaissement </vs-checkbox>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="Numero" v-model="depenseData.Numero" class="w-full" > Numero </vs-input>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="NumPieceCaisse" v-model="depenseData.NumPieceCaisse" class="w-full" > </vs-input>
            </div>


          </div>
      </tab-content>
  </form-wizard>
 </vx-card>

</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import {BFormFile} from 'bootstrap-vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import flatPickr from 'vue-flatpickr-component'

import 'flatpickr/dist/flatpickr.min.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'


export default {
  components: {
    BFormFile,
    FormWizard,
    flatPickr,
    TabContent
  },

  data () {
    return {
      language: 'fr',
      //   languages: lang,

      configDatePicker: {
        locale: FrenchLocale
      },
      configTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true
      },
      depenseData:[],


      IdOfficine: '',

      DateOp:'',
      HeurOp:'',
      Beneficiaire:'',
      codeValidation:'',
      Montant:'',
      Motif:'',
      IdUser:'',
      Encaiss_Vrai:false,
      MoyPaiement:'',
      Numero:'',
      CheminPreuv:[],
      NumPieceCaisse:'',

      NumCheque:''
    }
  },
  methods: {
    formSubmitted () {
      const payload = {
        IdOfficine: this.IdOfficine,
        DateOp: this.DateOp,
        HeurOp: this.HeurOp,
        Beneficiaire: this.Beneficiaire,
        codeValidation: this.codeValidation,
        Montant: this.Montant,
        Motif: this.Motif,
        IdUser: this.IdUser,
        Encaiss_Vrai: this.Encaiss_Vrai,
        MoyPaiement: this.MoyPaiement,
        Numero: this.Numero,
        CheminPreuv: this.CheminPreuv,
        NumPieceCaisse: this.NumPieceCaisse,
        NumCheque: this.NumCheque

      }
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading
      })
      console.log(payload)

      setTimeout(() => {
        this.$vs.loading.close()
        this.$router.push('/apps/client/client-list')
      }, 1500)


    //   this.$vs.loading({
    //     type:'sound'
    //   })


      // le dispatch sera ici
    //   console.log("Form submitted!")
      // .then((res) => {
      //   console.log('success ajout client>', res)
      //   this.reset_data()
      // })
      // .catch(err => {
      //   console.error('erreur ajout client >',err)
      // })
      // .finally(() => {
      //   this.$vs.loading.close()
      //   this.$vs.notify({
      //     title: 'Client Ajouté !',
      //     text: 'success !!',
      //     iconPack: 'feather',
      //     icon: 'icon-check',
      //     color: 'success'
      //   })
      // })
    },
    reset_data () {
      this.IdOfficine =

        this.DateOp =
        this.HeurOp =
        this.Beneficiaire =
        this.codeValidation =
        this.Montant =
        this.Motif =
        this.IdUser =
        this.MoyPaiement =
        this.Numero =
        this.NumCheque =
        this.NumPieceCaisse = ''


      this.Encaiss_Vrai = false
      this.CheminPreuv = []
    },
    successUpload (event) {
      this.$vs.notify({color:'success', title:'Upload Success', text:'Lorem ipsum dolor sit amet, consectetur'})
      const filePaths = event.currentTarget.response
      console.log(filePaths)
    },
    showCheminPreuvData () {
      console.log('CheminPreuvs >', this.CheminPreuv)
    },
    fetchDepense () {
      this.$store.dispatch('moduleDepense/fetchDepense', this.$route.params._id)
        .then(res => {
          this.depenseData = res.data.data
          console.log('Depense Datas >', this.depenseData)
        })

        .catch(err => {
          if (err.response.status === 404) {
            this.centre_not_found = true
            return
          }

          console.error(err)
        })
    }
  }

}
</script>

